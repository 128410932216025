// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-add-forms-js": () => import("./../../../src/pages/add-forms.js" /* webpackChunkName: "component---src-pages-add-forms-js" */),
  "component---src-pages-add-users-js": () => import("./../../../src/pages/add-users.js" /* webpackChunkName: "component---src-pages-add-users-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-forms-js": () => import("./../../../src/pages/forms.js" /* webpackChunkName: "component---src-pages-forms-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pw-forget-js": () => import("./../../../src/pages/pw-forget.js" /* webpackChunkName: "component---src-pages-pw-forget-js" */),
  "component---src-pages-signin-js": () => import("./../../../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-templates-client-page-template-js": () => import("./../../../src/templates/ClientPageTemplate.js" /* webpackChunkName: "component---src-templates-client-page-template-js" */),
  "component---src-templates-new-hire-form-page-template-js": () => import("./../../../src/templates/NewHireFormPageTemplate.js" /* webpackChunkName: "component---src-templates-new-hire-form-page-template-js" */),
  "component---src-templates-termination-form-page-template-js": () => import("./../../../src/templates/TerminationFormPageTemplate.js" /* webpackChunkName: "component---src-templates-termination-form-page-template-js" */)
}

